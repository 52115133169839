export default defineNuxtPlugin({
  name: 'analytics',
  parallel: true,
  setup() {
    const trackEvent = (event: string, ...params: any) => {
      console.log(`Tracking event: ${event}`, params)
      if (typeof window !== 'undefined' && window.gtag)
        window.gtag('event', event, ...params)
    }
    return {
      provide: {
        trackEvent,
      },
    }
  },
})
