<script setup lang="ts">
import { tv, type VariantProps } from 'tailwind-variants'

const props = withDefaults(defineProps<Props>(), {
  is: 'div',
})

const containerVariants = tv({
  base: 'mx-auto w-full max-w-[1190px] px-4 md:px-8',
  variants: {
    size: {
      lg: 'max-w-[1440px]',
      xl: 'max-w-[2800px]',
      full: 'max-w-none',
    },
  },
})

type ContainerVariants = VariantProps<typeof containerVariants>

interface Props {
  is?: keyof HTMLElementTagNameMap
  size?: ContainerVariants['size']
  class?: any
}
</script>

<template>
  <component :is="props.is" :class="containerVariants({ size, class: props.class })">
    <slot />
  </component>
</template>
