<script setup lang="ts">
import { Primitive, type PrimitiveProps } from 'radix-vue'
import { tv, type VariantProps } from 'tailwind-variants'

type ButtonVariants = VariantProps<typeof buttonVariants>

interface Props extends PrimitiveProps {
  variant?: ButtonVariants['variant']
  // size?: ButtonVariants['size']
  class?: any
}

const props = withDefaults(defineProps<Props>(), {
  as: 'button',
})

const buttonVariants = tv({
  base: 'inline-flex items-center justify-center gap-[10px] rounded-lg border border-blue px-5 py-[11px] font-semibold text-white active:outline active:outline-4 active:outline-blue-light/25 disabled:border-gray-3 disabled:bg-gray-3 disabled:text-gray-1 disabled:active:outline-0',
  variants: {
    variant: {
      primary: 'bg-blue hover:bg-blue-dark',
      secondary: 'border-black bg-black',
      outline: 'border-blue bg-white text-blue hover:border-blue-dark hover:text-blue-dark',
      bare: 'border-transparent text-blue hover:text-blue-dark disabled:border-0 disabled:bg-transparent',
      pill: 'gap-1 rounded-full bg-blue py-1 hover:bg-blue-dark',
    },
    // size: {
    //   md: '',
    //   xs: 'h-7 rounded px-2',
    //   sm: 'h-9 rounded-md px-3',
    //   lg: 'h-11 rounded-md px-8',
    //   icon: 'size-10',
    // },
  },
  defaultVariants: {
    variant: 'primary',
    // size: 'md',
  },
})
</script>

<template>
  <Primitive
    :as="props.as"
    :as-child="props.asChild"
    :class="buttonVariants({ variant, /* size,*/ class: props.class })"
  >
    <slot />
  </Primitive>
</template>
