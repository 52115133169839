import type { RouterConfig } from '@nuxt/schema'
// TODO: check if we need scrollBehavior, as we use `scrollBehaviorType: 'smooth'`
// import scrollBehavior from './router.scrollBehavior'

export default <RouterConfig>{
  scrollBehaviorType: 'smooth',
  routes(_routes) {
    return [
      {
        name: 'about',
        path: '/about',
        component: () => import('~/pages/about.vue'),
      },
      {
        name: 'detail',
        path: '/inventory/:make([^/]+)-:model([^/]+)-:year([0-9]+)-:vin([a-zA-Z0-9]{17})',
        component: () => import('~/pages/detail.vue'),
      },
      {
        name: 'disclaimer',
        path: '/disclaimer',
        component: () => import('~/pages/disclaimer.vue'),
      },
      {
        name: 'home',
        path: '/',
        component: () => import('~/pages/home.vue'),
      },
      {
        name: 'privacy-policy',
        path: '/privacy-policy',
        component: () => import('~/pages/privacy-policy.vue'),
      },
      {
        name: 'saved-vehicles',
        path: '/saved-vehicles',
        component: () => import('~/pages/saved-vehicles.vue'),
      },
      {
        name: 'search',
        path: '/cars/:make([^/]*)?/:model([^/]*)?/:trim([^/]*)?',
        component: () => import('~/pages/search.vue'),
        meta: {
          key: 'search',
          viewTransition: false,
          // pageTransition: { name: 'page', mode: 'out-in' },
        },
      },
      {
        name: 'sitemap',
        path: '/sitemap',
        component: () => import('~/pages/sitemap.vue'),
      },
      {
        name: 'terms-of-service',
        path: '/tos',
        component: () => import('~/pages/terms-of-service.vue'),
      },
      {
        name: 'styleguide',
        path: '/styleguide',
        component: () => import('~/pages/styleguide.vue'),
      },
      {
        name: 'ico',
        path: '/sell',
        component: () => import('~/pages/sell-us-your-car.vue'),
      },
    ]
  },
  // scrollBehavior,
}
