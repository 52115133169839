<script setup lang="ts">
import type { NuxtError } from '#app'

const props = defineProps({
  error: Object as () => NuxtError,
})

const handleError = () => clearError({ redirect: '/' })

let errorTitle = 'Oops! Looks like we’re having engine trouble.'
let errorDescription = 'The CarStory mechanics have been notified. Sorry for the inconvenience, please check back later.'

useSeoMeta({
  title: 'Engine Trouble | Carstory.com',
})

if (props.error?.statusCode === 404) {
  errorTitle = 'Oops! Looks like we’re in a jam.'
  errorDescription = 'We can’t seem to find the page you were looking for.'
  useSeoMeta({
    title: 'Page not found | Carstory.com',
  })
}

if (props.error?.statusCode === 307) {
  errorTitle = 'Temporary unavailable'
  errorDescription = 'This page has been disabled temporarily, apologize for any inconvenience caused.'
  useSeoMeta({
    title: 'Temporary unavailable | Carstory.com',
  })
}

if (props.error?.statusCode === 410) {
  errorTitle = 'Vehicle no longer available'
  errorDescription = 'The vehicle you are looking for is no longer available. Please check out our latest inventory.'
  useSeoMeta({
    title: 'Vehicle found a new garage | Carstory.com',
  })
}
</script>

<template>
  <NuxtLayout>
    <UiContainer class="my-auto">
      <div class="flex flex-col items-center gap-4 py-8 text-center">
        <img src="~/assets/icons/car-repair.svg" alt="">
        <h1 class="text-5xl font-bold">
          {{ errorTitle }}
        </h1>
        <p>
          {{ errorDescription }}
        </p>
        <UiButton class="mt-4" @click="handleError">
          Go back home
        </UiButton>
      </div>
    </UiContainer>
  </NuxtLayout>
</template>
