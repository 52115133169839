
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as aboutzuz1krw6KuMeta } from "/usr/src/pages/about.vue?macro=true";
import { default as detailBNXlJXOlAdMeta } from "/usr/src/pages/detail.vue?macro=true";
import { default as disclaimeriZapazttZnMeta } from "/usr/src/pages/disclaimer.vue?macro=true";
import { default as home3B7vQsoAfhMeta } from "/usr/src/pages/home.vue?macro=true";
import { default as privacy_45policyhkIHKnlM3uMeta } from "/usr/src/pages/privacy-policy.vue?macro=true";
import { default as saved_45vehiclestRPgg2DQr1Meta } from "/usr/src/pages/saved-vehicles.vue?macro=true";
import { default as searchUKaz6mI3aPMeta } from "/usr/src/pages/search.vue?macro=true";
import { default as sell_45us_45your_45car7r7j1uzvBcMeta } from "/usr/src/pages/sell-us-your-car.vue?macro=true";
import { default as sitemapMOATK3KFIvMeta } from "/usr/src/pages/sitemap.vue?macro=true";
import { default as styleguide4122ft9eMDMeta } from "/usr/src/pages/styleguide.vue?macro=true";
import { default as terms_45of_45serviceEguaE9qMTtMeta } from "/usr/src/pages/terms-of-service.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/usr/src/pages/about.vue")
  },
  {
    name: "detail",
    path: "/detail",
    component: () => import("/usr/src/pages/detail.vue")
  },
  {
    name: "disclaimer",
    path: "/disclaimer",
    component: () => import("/usr/src/pages/disclaimer.vue")
  },
  {
    name: "home",
    path: "/home",
    component: () => import("/usr/src/pages/home.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/usr/src/pages/privacy-policy.vue")
  },
  {
    name: "saved-vehicles",
    path: "/saved-vehicles",
    component: () => import("/usr/src/pages/saved-vehicles.vue")
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/usr/src/pages/search.vue")
  },
  {
    name: "sell-us-your-car",
    path: "/sell-us-your-car",
    component: () => import("/usr/src/pages/sell-us-your-car.vue")
  },
  {
    name: "sitemap",
    path: "/sitemap",
    component: () => import("/usr/src/pages/sitemap.vue")
  },
  {
    name: "styleguide",
    path: "/styleguide",
    component: () => import("/usr/src/pages/styleguide.vue")
  },
  {
    name: "terms-of-service",
    path: "/terms-of-service",
    component: () => import("/usr/src/pages/terms-of-service.vue")
  }
]